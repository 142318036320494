<template>
  <div class="table-container">
    <b-table
      :data="groups"
      :loading="loading"
      striped
      hoverable
      bordered
      paginated
      backend-pagination
      pagination-size="is-small"
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
    >
      <b-table-column field="index" label="Index" sortable v-slot="props">
        {{ sortOrder === 'asc' ? props.index + offset + 1 : total - props.index - offset }}
      </b-table-column>

      <b-table-column field="id" label="ID" sortable v-slot="props">
        <router-link :to="{ name: 'group', params: { id: props.row.id } }">
          {{ props.row.id }}
        </router-link>
      </b-table-column>

      <b-table-column field="name" label="Name" sortable v-slot="props">
        <router-link :to="{ name: 'devices', params: { groupId: props.row.id } }">
          {{ props.row.name }}
        </router-link>
      </b-table-column>

      <b-table-column field="enabled" label="Enabled" sortable v-slot="props">
        {{ props.row.enabled ? 'yes' : 'no' }}
      </b-table-column>

      <b-table-column field="actions" label="Actions" v-slot="props">
        <router-link :to="{ name: 'group', params: { id: props.row.id } }">
          <b-button size="is-small" title="Detail">
            <b-icon icon="eye" size="is-small" />
          </b-button>
        </router-link>
      </b-table-column>

      <b-table-column field="link" label="" v-slot="props">
        <router-link :to="{ name: 'devices', params: { groupId: props.row.id } }"> Devices </router-link>
        |
        <router-link :to="{ name: 'callbacks', params: { groupId: props.row.id } }"> Callbacks </router-link>
      </b-table-column>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>Nothing here.</p>
          </div>
        </section>
      </template>

      <template slot="bottom-left">
        <b-button tag="router-link" :to="{ name: 'group-add' }" type="is-success" icon-left="plus-box"
          >Add Group</b-button
        >

        <b-button v-on:click="fetch" type="is-info" icon-left="refresh" outlined style="margin-left: 100px"
          >Refresh data</b-button
        >
      </template>
    </b-table>
  </div>
</template>

<script>
import Group from '../models/Group';

export default {
  name: 'groups',
  data() {
    return {
      groups: [],
      total: 0,
      page: 1,
      perPage: 20,
      sortField: 'name',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      loading: false,
      offset: 0,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      const limit = this.perPage;
      this.offset = (this.page - 1) * this.perPage;
      const res = await Group.getAll(
        this.$route.params.organizationId,
        this.sortField,
        this.sortOrder,
        limit,
        this.offset
      );
      this.groups = res.data;
      this.total = parseInt(res.headers['x-total'], 10);
      this.loading = false;
    },
    onPageChange(page) {
      this.page = page;
      this.fetch();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetch();
    },
  },
};
</script>
